import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const SkeletonLoader = () => {
  return (
    <div style={{ padding: '1rem' }}>
      {/* Header */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
        <Skeleton width={150} height={40} />
        <Skeleton width={200} height={40} />
      </div>

      {/* Buttons */}
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        <Skeleton width={80} height={30} />
        <Skeleton width={80} height={30} />
        <Skeleton width={80} height={30} />
        <Skeleton width={80} height={30} />
      </div>

      {/* Table */}
      <div>
        {/* Table Header */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
          {Array(7)
            .fill(0)
            .map((_, index) => (
              <Skeleton key={index} width={120} height={20} />
            ))}
        </div>

        {/* Table Rows */}
        {Array(7)
          .fill(0)
          .map((_, rowIndex) => (
            <div
              key={rowIndex}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '0.5rem',
              }}
            >
              {Array(7)
                .fill(0)
                .map((_, cellIndex) => (
                  <Skeleton key={cellIndex} width={120} height={20} />
                ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SkeletonLoader;
