import React, { useState } from 'react';
import './Estimation.scss';
import happilogo from '../../assets/happi_h_logo.png'

const EstimationList = () => {
    const [data,setData]=useState();
    const [clickedIndex,setClickedIndex]=useState(-1);
    const handleOnCardClick=(index)=>{
        setClickedIndex(index);
        setTimeout(()=>{
            setClickedIndex(-1)
        },1000)
    }

    const cardData=(title,value)=>{
        return  <div className='d-flex gap-3'>
        <div className='card-subheaders'>
        <p className='fw-bold'>{title}</p>
        </div>
        <div className='card-sub-text'>
        <p>{value}</p>
        </div>
    </div>
    }
  return (
   <>
   <div className='estimation-list row'>
    <div className='relative header-bg'>
    <img src={happilogo} alt="hapi-logo" height={"30px"} width={"30px"}/>
    </div>
   <div className='col-12 offset-md-4 col-md-4'>
   { 
   new Array(30).fill({branch:"Ameerpet",Brand:"AA OPPO",model:"Vivo 5G with HD Camera 108MP",purchas_date:"11-12-2-2024",status:'Approved',coupn_status:"not used"}).map((item,index)=><div key={index} className={`estimation-list-card m-2 ${index==clickedIndex&&`clicked`}`} onClick={()=>handleOnCardClick(index)}>
   <div className='p-3'>
    {cardData("Brand",item.Brand)}
    {cardData("Branch",item.branch)}
    {cardData("Model",item.model)}
    {cardData("Purchase Date",item.purchas_date)}
    {cardData("Coupn Status",item.coupn_status)}
   </div>
 
   </div>)
   }
     </div>
   </div>
 
   </>
  )
}

export default EstimationList