import React, { useEffect, useState } from 'react'
import { MultiSelect } from "react-multi-select-component";
import './finance.scss';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select'




const FinanceReports = () => {
  const [selected, setSelected] = useState([]);
  const defaultStartDate = new Date();
  const defaultEndDate = new Date();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState([]);
  const [itemsList, setItemsList] = useState([]);
  const [financeReportFormData, setFinanceReportFormData] = useState({ submitBtnLoader: false });
  const [selectedItem, setSelectedItem] = useState([]);

  useEffect(() => {
    document.title = 'Finance Reports'
    getProductsData()
  }, []);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const getProductsData = async () => {
    try {
      let { data } = await axios('https://mis-api.happimobiles.com/api/finance/productNames');
      setProductList(data?.productNames)
    }
    catch (e) {
      toast.error("Network error")

    }

  }

  const onProductSelection = async (val) => {
    setBrandList({ loader: true })
    if (!val.length) return setSelectedProduct(val)
    setSelectedProduct(val)
    let { data } = await axios(`https://mis-api.happimobiles.com/api/finance/brandNames?product_name=${val.map(item => item.label).toString()}`)
    setBrandList(data?.rows)
  }
  const onProductSingleSelection = async (val) => {
    console.log("val", val)
    setBrandList({ loader: true })
    // if(!val?.length) return setSelectedProduct(val)
    setSelectedProduct(val)
    let { data } = await axios(`https://mis-api.happimobiles.com/api/finance/brandNames?product_name=${val?.value}`)
    setBrandList(data?.rows)
  }

  const onBrandSelection = async (val) => {
    // if(!val.length) return setSelectedBrand(val)
    setSelectedBrand(val)
    let { data } = await axios(`https://mis-api.happimobiles.com/api/finance/itemNames?brand_name=${val.value}`)
    setItemsList(data?.rows)
  }

  const getProductOptions = () => productList.length > 0 ? productList?.map(item => ({ label: item, value: item })) : []
  const getBrandOptions = () => brandList.length > 0 ? brandList?.map(item => ({ label: item.brand_name, value: item.brand_name })) : []
  const getItemsOptions = () => itemsList.length > 0 ? itemsList?.map(item => ({ label: item.item_name, value: item.item_name, ...item })) : []

  const onReportSubmit = async () => {
    let startdate = new Date(startDate);
    let enddate = new Date(endDate);

    if(!financeReportFormData?.mop) return toast.error("Please Enter Mop")

    let payload = {
      item_name: selectedItem?.item_name,
      from_date: startDate.toISOString().split('T')[0],
      to_date: endDate.toISOString().split('T')[0],
      dp_mop: financeReportFormData?.mop,
    }
    
    setFinanceReportFormData(prev => ({ ...prev, submitBtnLoader: true }))

    // let payload={

    //     from_date:startDate.toISOString().split('T')[0],
    //     to_date:endDate.toISOString().split('T')[0],
    //     sellout_mop:financeReportFormData?.mop,
    //     sellout_disc_pct:financeReportFormData?.sale_disc_per,
    //     sellout_disc:financeReportFormData?.sale_disc,
    //     sellout_spike:financeReportFormData?.spike,
    //     sellout_target_disc:financeReportFormData?.sale_disc_target
    // }

    try {
      let { data } = await axios.post(`https://mis-api.happimobiles.com/api/finance/updateDp`, payload);
      if (data.status) {
        setSelectedItem([]);
        setSelectedBrand([]);
        setSelectedProduct([]);
        toast.success("Data Submitted Successfully")
        setFinanceReportFormData({ submitBtnLoader: false })
      }
    }
    catch (e) {
      console.log(e.response.data, "---")
      toast.error(e?.response?.data?.message);
      setSelectedItem([]);
      setSelectedBrand([]);
      setSelectedProduct([]);
      setFinanceReportFormData({ submitBtnLoader: false })
    }
  }

  const onFormDataChange = (e) => {
    setFinanceReportFormData((prev => ({ ...prev, [e.target.name]: e.target.value })))

  }
  return (
    <>
      <div className='finance-container'>
        <div className='d-flex title-calendar-container align-items-center justify-content-around'>
          <h5>Finance Reports</h5>
          <div className=''>
            <DatePicker
              selectsRange={true}
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={onChange}
              disabled={financeReportFormData?.submitBtnLoader}
              isClearable={true} />
          </div>
        </div>
        <div className='formdata'>
          <div className='row'>
            <div className={`col-md-4`}>
              <label>Product Name</label>
              <Select
                options={getProductOptions()}
                value={selectedProduct}
                onChange={onProductSingleSelection}
              />
              {/* <MultiSelect
        options={getProductOptions}
        onChange={onProductSelection}
        isLoading={brandList?.loader}
        labelledBy="Select"
        className={financeReportFormData?.submitBtnLoader?'disabled':""}
        disabled={financeReportFormData?.submitBtnLoader}
      /> */}
            </div>
            <div className='col-md-4'>
              <label>Brand Name</label>
              <Select
                options={getBrandOptions()}
                value={selectedBrand}
                onChange={onBrandSelection}
              />
              {/* <MultiSelect
        options={getBrandOptions}
        value={selectedBrand}
        onChange={onBrandSelection}
        className={financeReportFormData?.submitBtnLoader?'disabled':""}
        isLoading={brandList?.loader}
        // isLoading={true}
        labelledBy="Select"
        disabled={financeReportFormData?.submitBtnLoader}
    
      /> */}
            </div>
            <div className='col-md-4'>
              <label>Item Name</label>
              <Select
                options={getItemsOptions()}
                value={selectedItem}
                onChange={setSelectedItem}
              />
              {/* <MultiSelect
        options={getItemsOptions}
        value={selectedItem}
        onChange={setSelectedItem}
        labelledBy="Select"
        className={financeReportFormData?.submitBtnLoader?'disabled':""}
        disabled={financeReportFormData?.submitBtnLoader}
    
      /> */}
            </div>

          </div>
          <div className='row mt-2'>
            <div className='col-md-4'>
              <input type="text" placeholder='MOP' name="mop" value={financeReportFormData?.mop || ""} className='form-control' onChange={onFormDataChange} disabled={financeReportFormData?.submitBtnLoader} />
            </div>
            <div className='col-md-4 mt-2 mt-md-0'>
              <button className={`btn btn-danger w-100 ${financeReportFormData?.submitBtnLoader ? "disabledBtn" : ""}`} onClick={onReportSubmit} disabled={financeReportFormData?.submitBtnLoader}>{financeReportFormData?.submitBtnLoader ? <Spinner animation="border" size="sm" /> : "Submit"}</button>

            </div>
            {/* <div className='col-md-4 mt-2 mt-md-0'>
            <input type="text"  className='form-control' value={financeReportFormData?.sale_disc||""} name="sale_disc" placeholder='Sell Out Discount Value' onChange={onFormDataChange} disabled={financeReportFormData?.submitBtnLoader}  />
        </div> */}
          </div>
          <div className='row mt-2'> 
            {/* <div className='col-md-4 '>
            <input type="text" className='form-control' value={financeReportFormData?.sale_disc_target||""}  name="sale_disc_target" placeholder='Target Discount' onChange={onFormDataChange} disabled={financeReportFormData?.submitBtnLoader}  />
        </div>
        <div className='col-md-4 mt-2  mt-md-0'>
            <input type="text" className='form-control'  value={financeReportFormData?.spike||""} name="spike" placeholder='Spike' onChange={onFormDataChange} disabled={financeReportFormData?.submitBtnLoader} />
        </div> */}
            <div className='col-md-4 d-flex align-items-center justify-content-end mt-2  mt-md-0'>
              {/* <button className={`btn btn-danger w-100 ${financeReportFormData?.submitBtnLoader?"disabledBtn":""}` } onClick={onReportSubmit} disabled={financeReportFormData?.submitBtnLoader}>{financeReportFormData?.submitBtnLoader?<Spinner animation="border" size="sm" />:"Submit"}</button> */}
            </div>
          </div>

        </div>
      </div>
    </>

  )
}

export default FinanceReports