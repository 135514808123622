import React, { useRef, useState } from "react";
import * as XLSX from "xlsx";
import "./tabs.scss";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Excelsheetupload = () => {
  const [jsonData, setJsonData] = useState(null);
  const fileRef = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(sheet);
        setJsonData(json);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const handleSubmit = async () => {
    if (!jsonData || jsonData.length === 0) {
      toast.error("No valid data to submit!");
      return;
    }

    try {
      const response = await axios.post(
        "https://dev-services.happimobiles.com/api/tabs",
        jsonData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        toast.success("Submitted Successfully");
        setJsonData(null);
        fileRef.current.value = "";
      }
    } catch (e) {
      console.error(e);
      toast.error("Submission failed. Please try again.");
    }
  };

  return (
    <div className="position-relative rel">
      <div
        style={{ minHeight: "100vh" }}
        className="d-flex flex-column justify-content-center align-items-center container-upload"
      ></div>
      <div className="bg-white p-5 card-container position-absolute">
        <h3>Please Upload .xlsx format files</h3>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={handleFileUpload}
          ref={fileRef}
          className="form-control"
        />
        <div className="d-flex justify-content-end mt-3">
          <button className="btn btn-success" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Excelsheetupload;
