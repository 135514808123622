import React, { useEffect, useState } from "react";
import "./happidicount.scss";
import happibg from '../../assets/happi_h_logo.png'
import { createClient } from "@supabase/supabase-js";
import Select from 'react-select';
import moment from "moment";
import { currencyFormatter } from "../../utils/currencyformatter";



const Anon_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICJyb2xlIjogImFub24iLAogICJpc3MiOiAic3VwYWJhc2UiLAogICJpYXQiOiAxNzIyMzY0MjAwLAogICJleHAiOiAxODgwMTMwNjAwCn0.w57RqFI1rHGlCHiBEbTDbfUABPxgNdXN8GVVYhyGzK4'

const supabase = createClient("https://apps.happimobiles.com", Anon_key);


const EstimationSlip = () => {
  const [data, setData] = useState({ purchase_date: new Date() });
  const [storeList, setStoreList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState({ label: 'AA IPHONE', value: 'AA IPHONE' });
  const [selectedModel, setSelectedModel] = useState();

  // console.log("data",data)

  useEffect(() => {
    getData();
    getEmployee();
    getStore();
    getBarnd();
  }, []);

  async function getData() {
    const { data } = await supabase.from("discount_estimation").select();
    // console.log("data", data)
    // setData(data);
  }

  async function getEmployee() {
    const { data } = await supabase.from("employee").select();
    setEmployeeList(data)
  }
  async function getStore() {
    const { data } = await supabase.from("store").select();
    setStoreList(data.map(item => ({ label: item.store_name, value: item.store_label })))
  }
  async function getBarnd() {
    const { data } = await supabase.from("item").select();
    setBrandList(data)
  }


  const getBrandOptions = () => {
    return brandList.map(item => ({ label: item.brand_name, value: item.brand_name }))
  }

  const getModelOptions = () => {
    return brandList.filter(item => item.brand_name == selectedBrand?.label).map(item => ({ label: item.item_name, value: item.item_code, mop: item?.mop }))
  }

  const handleOnInputChange = (e) => {
    setData(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const calcuateAmountPaidByCustomer = () => {
    let totalDisocuntAmount = ['cashback_happi', "cashback_brand", "cashback_mobikwik", "cashback_others"].reduce((acc, item) => {
      if (data?.[item]) {
        acc = acc + Number(data?.[item])
      }
      return acc
    }, 0)
    let totalAttachementAmount = ['attachements_flashgard', 'attachment_happi_care', "Acc_1", "Acc_2", "Acc_3", "Acc_4", "Acc_5"].reduce((acc, item) => {
      if (data?.[item]) {
        acc = acc + Number(data?.[item])
      }
      return acc
    }, 0)
    //  console.log('totalAttachementAmount',totalAttachementAmount);
    let calcualteWithDisocunt = Number(selectedModel?.mop) - totalDisocuntAmount;
    return {final_amount:currencyFormatter(Number(calcualteWithDisocunt + totalAttachementAmount)),final_discount_amount:totalDisocuntAmount};
  }
  const handleOnSubmit = () => {
    let payload = data;
  }

  return (
    <div className="estimation-slip estimation-slip-table">
      <div className="header">
        <div className="sub-header d-flex align-items-center gap-2">
          <img src={happibg} height={"40px"} width={"40px"} loading="lazy" />
          <h1 className="m-0">Happi Estimation Slip</h1>
        </div>

      </div>
      <div className="section">
        <table className="">
          <tr>
            <td className="label">Date of Purchase:</td>
            <td className="input">
              <p className="m-0 ms-1 p-1">{moment(new Date(data.purchase_date)).format('llll')}</p>
              {/* <input type="date" name="purchase_date" onChange={handleOnInputChange} value={moment(new Date(data.purchase_date)).format('llll')} /> */}
            </td>
          </tr>
          <tr>
            <td className="label">Customer Name:</td>
            <td className="input">
              <input type="text" placeholder="Enter customer name" name="customer_name" onChange={handleOnInputChange} />
            </td>
          </tr>
          <tr>
            <td className="label">Contact Number:</td>
            <td className="input">
              <input type="tel" placeholder="Enter contact number" name="cutsomer_contact_number" onChange={handleOnInputChange} />
            </td>
          </tr>
          <tr>
            <td className="label">Date of Birth:</td>
            <td className="input">
              <input type="date" name="customer_dob" onChange={handleOnInputChange} value={data?.customer_dob || ""} /></td>
          </tr>
          <tr>
            <td className="label">Blood Group:</td>
            <td className="input">
              <input type="text" placeholder="Enter blood group" name="customer_blood_group" value={data?.customer_blood_group || ""} onChange={handleOnInputChange} /></td>
          </tr>
          <tr>
            <td className="label">Branch:</td>
            <td className="input">
              <input type="text" placeholder="Enter branch" />
            </td>
          </tr>
          <tr>
            <td className="label">Brand:</td>
            <td className="input">
              <Select
                value={selectedBrand}
                onChange={setSelectedBrand}
                options={getBrandOptions()}
                isSearchable
                placeholder="Select Brand"
              />
              {/* <input type="text" placeholder="Enter brand" /> */}
            </td>
          </tr>
          <tr>
            <td className="label">Model:</td>
            <td className="input">
              <Select
                value={selectedModel}
                onChange={setSelectedModel}
                options={getModelOptions()}
                isSearchable
                placeholder="Select Model"
              />
            </td>
          </tr>
          <tr>
            <td className="label">Colour & IMEI:</td>
            <td className="input">
              <input type="text" placeholder="Enter colour & IMEI" name="imei_no" value={data?.imei_no || ""} onChange={handleOnInputChange} />
            </td>
          </tr>
          <tr>
            <td className="label">Variant:</td>
            <td className="input">
              <input type="text" placeholder="Enter variant" name="variant" value={data?.variant || ""} onChange={handleOnInputChange} />
            </td>
          </tr>
          <tr>
            <td className="label">MOP:</td>
            <td className="input">
              <input type="text" value={selectedModel?.mop ? currencyFormatter(selectedModel?.mop) : ""} placeholder="Enter MOP" disabled />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="section-tr"><div className="cashback-header">Cashback</div>
            </td>
          </tr>
          <tr>
            <td className="label">Happi:</td>
            <td className="input">
              <input type="number" placeholder="Enter amount" name="cashback_happi" value={data?.cashback_happi || ""} onChange={handleOnInputChange} />
            </td>
          </tr>
          <tr>
            <td className="label">Brand:</td>
            <td className="input">
              <input type="number" placeholder="Enter amount" name="cashback_brand" value={data?.cashback_brand || ""} onChange={handleOnInputChange} />
            </td>
          </tr>
          <tr>
            <td className="label">MobiKwik:</td>
            <td className="input">
              <input type="number" placeholder="Enter amount" name="cashback_mobikwik" value={data?.cashback_mobikwik || ""} onChange={handleOnInputChange} />
            </td>
          </tr>
          <tr>
            <td className="label">Others:</td>
            <td className="input">
              <input type="number" placeholder="Enter amount" name="cashback_others" value={data?.cashback_others || ""} onChange={handleOnInputChange} />
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="section-tr"><div className="attachments-header">Attachments</div>
            </td>
          </tr>
          <tr>
            <td className="label">Flashgard:</td>
            <td className="input">
              <input type="text" placeholder="Enter details" name="attachements_flashgard" value={data?.attachements_flashgard || ""} onChange={handleOnInputChange} />
            </td>
          </tr>
          <tr>
            <td className="label">Happi Care:</td>
            <td className="input">
              <input type="text" placeholder="Enter details" name="attachment_happi_care" value={data?.attachment_happi_care || ""} onChange={handleOnInputChange} />
            </td>
          </tr>


          {[...Array(5)].map((_, i) => (
            <tr key={i}>
              <td className="label">{`Acc ${i + 1}:`}</td>
              <td className="input">
                <input type="text" placeholder="Enter details" name={`Acc_${i + 1}`} value={data?.[`Acc_${i + 1}`] || ""} onChange={handleOnInputChange} />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={2} className="section-tr"><div className="payment-header" onChange={handleOnInputChange}>Mode of Payment by Customer</div>
            </td>
          </tr>
          <tr>
            <td className="label">Cash:</td>
            <td className="input">
              <input type="number" placeholder="Enter amount" name="payement_cash" onChange={handleOnInputChange} />
            </td>
          </tr>
          <tr>
            <td className="label">Finance:</td>
            <td className="input">
              <input type="number" placeholder="Enter amount" name="payement_finance" value={data?.payement_finance || ""} onChange={handleOnInputChange} />
            </td>
          </tr>
          <tr>
            <td className="label">Card:</td>
            <td className="input">
              <input type="number" placeholder="Enter amount" name="payement_card" value={data?.payement_card || ""} onChange={handleOnInputChange} />
            </td>
          </tr>
          <tr className="">
            <td className="label final-disc-amount">Final Discount Request Amount:</td>
            <td className="input final-disc-amount">
              <input type="text" placeholder="Enter final amount" value={selectedModel?.mop?calcuateAmountPaidByCustomer()?.final_discount_amount:""} disabled />
            </td>
          </tr>
          <tr>
            <td className="label final-paid-amount">Final Amount To Be Paid By Customer:</td>
            <td className="input final-paid-amount">
              <input type="text" placeholder="Enter final amount" value={selectedModel?.mop?calcuateAmountPaidByCustomer()?.final_amount:""} disabled/>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="p-1">
              <div className="d-flex justify-content-end gap-3">
                <button className="btn btn-warning">Clear All</button>
                <button className="btn btn-success" onClick={handleOnSubmit}>Submit</button>
              </div>

            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default EstimationSlip;