import React, { useState } from "react";
import "./careers.scss";

const HappiCarees = () => {
  const [age, setAge] = useState("");

  const handleDateChange = (e) => {
    const dob = new Date(e.target.value);
    const today = new Date();
    const calculatedAge = today.getFullYear() - dob.getFullYear();
    setAge(calculatedAge);
  };

  return (
    <div className="form-container">
      <h1>Registration Form</h1>
      <form>
        {/* Salutation */}
        <div className="form-group">
          <label>Salutation:</label>
          <div>
            <label><input type="radio" name="salutation" value="Mr." /> Mr.</label>
            <label><input type="radio" name="salutation" value="Mrs." /> Mrs.</label>
            <label><input type="radio" name="salutation" value="Miss" /> Miss</label>
          </div>
        </div>

        {/* First Name */}
        <div className="form-group">
          <label>First Name:</label>
          <input type="text" name="firstName" placeholder="Enter First Name" required />
        </div>

        {/* Last Name */}
        <div className="form-group">
          <label>Last Name:</label>
          <input type="text" name="lastName" placeholder="Enter Last Name" required />
        </div>

        {/* Gender */}
        <div className="form-group">
          <label>Gender:</label>
          <div>
            <label><input type="radio" name="gender" value="Male" /> Male</label>
            <label><input type="radio" name="gender" value="Female" /> Female</label>
          </div>
        </div>

        {/* Date of Birth */}
        <div className="form-group">
          <label>Date of Birth:</label>
          <input type="date" name="dob" onChange={handleDateChange} required />
        </div>

        {/* Age */}
        <div className="form-group">
          <label>Age:</label>
          <input type="text" name="age" value={age} readOnly />
        </div>

        {/* Married Status */}
        <div className="form-group">
          <label>Married Status:</label>
          <div>
            <label><input type="radio" name="marriedStatus" value="Married" /> Married</label>
            <label><input type="radio" name="marriedStatus" value="Unmarried" /> Unmarried</label>
            <label><input type="radio" name="marriedStatus" value="Divorced" /> Divorced</label>
          </div>
        </div>

        {/* Email ID */}
        <div className="form-group">
          <label>Email ID:</label>
          <input type="email" name="email" placeholder="Enter Email" required />
        </div>

        {/* Mobile Number */}
        <div className="form-group">
          <label>Mobile Number:</label>
          <input type="tel" name="mobile" pattern="\d{10}" placeholder="Enter Mobile Number" required />
        </div>

        {/* Current Organisation */}
        <div className="form-group">
          <label>Current Organisation:</label>
          <select name="organisation" required>
            <option value="">Select Organisation</option>
            <option value="Big C">Big C</option>
            <option value="LOT">LOT</option>
            <option value="OTHERS">OTHERS</option>
          </select>
          <input type="text" name="otherOrganisation" placeholder="Specify if OTHERS" />
        </div>

        {/* Current Organisation Experience */}
        <div className="form-group">
          <label>Current Organisation Experience:</label>
          <input type="number" name="experience" placeholder="No of Years" required />
        </div>

        {/* Current Designation */}
        <div className="form-group">
          <label>Current Designation:</label>
          <input type="text" name="designation" placeholder="Enter Designation" required />
        </div>

        {/* Current Salary per Month */}
        <div className="form-group">
          <label>Current Salary per Month:</label>
          <input type="text" name="salary" maxLength="6" placeholder="Enter Salary" required />
        </div>

        {/* Incentive per Month */}
        <div className="form-group">
          <label>Incentive per Month:</label>
          <input type="text" name="incentive" maxLength="5" placeholder="Enter Incentive" required />
        </div>

        {/* Total Experience */}
        <div className="form-group">
          <label>Total Experience:</label>
          <select name="totalExperience" required>
            {[...Array(31).keys()].map((year) => (
              <option key={year} value={year}>{year} Yrs</option>
            ))}
          </select>
        </div>

        {/* Department */}
        <div className="form-group">
          <label>Department:</label>
          <input type="text" name="department" placeholder="Enter Department" required />
        </div>

        {/* Selection Level */}
        <div className="form-group">
          <label>Selection Level:</label>
          <div>
            <label><input type="radio" name="selectionLevel" value="Cold" /> Cold</label>
            <label><input type="radio" name="selectionLevel" value="Warm" /> Warm</label>
            <label><input type="radio" name="selectionLevel" value="Hot" /> Hot</label>
          </div>
        </div>

        {/* Submit Button */}
        <div className="form-group">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
};

export default HappiCarees;
